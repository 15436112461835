<template>
  <div class="page-title-wrapper">
    <h1 class="primary--text">
      <slot></slot>
      {{ text }}
    </h1>
  </div>
</template>

<script>
export default {
  name: "PageTitle",
  props: {
    text: {
      type: String,
      default: "",
    },
  },
};
</script>

<style lang="scss" scoped>
.page-title-wrapper {
  &.center-icon {
    h1 {
      display: flex;
      align-items: center;
    }
  }

  h1 {
    font-size: 1.625rem;
    font-weight: $font-bold;
    letter-spacing: 0.65px;
  }
}
</style>